.quickview-book-modal--container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 10px;
}

.quickview-book-modal--header-and-close {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quickview-book-modal--header-and-close i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #113b58;
  color: #dfe3e6;
}

.quickview-book-modal--header-and-close i:hover {
  cursor: pointer;
  background-color: #5c809e;
}

.quickview-book-modal--container h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.quickview-book-modal--author {
  padding-top: 0;
  padding-bottom: 15px;
}

.quickview-book-modal--description {
  font-style: italic;
}
