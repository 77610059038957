/* TODO Add site wide styles */
html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  background-color: #f7f7f7;
  min-height: 100vh;
}

.errors {
  color: rgb(255, 1, 1);
  display: flex;
  justify-content: center;
  margin: 0;
}
