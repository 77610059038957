.landing-featured-genre--header:hover {
  cursor: pointer;
  color: #5c809e;
}

.landing-featured-genre--tile-row {
  display: flex;
  gap: 2rem;
  transition: all 0.5s ease-in;
}

.landing-featured-genre--tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 2s ease-in-out;
}

.landing-featured-genre--book:hover {
  cursor: pointer;
}

.landing-featured-genre--book {
  max-width: 250px;
  max-height: 350px;
  transition: 2s ease-in-out;
}

.landing-featured-genre--no-genre-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
}
