.login-form--container,
.login-form--container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form--container h1 {
  padding: 0;
  margin: 0;
  margin-top: 40px;
}

.login-form--container input {
  width: 100%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 30px;
  margin: 5px;
}

.login-form--container label {
  color: rgb(126, 126, 126);
  width: 500px;
}

.login-form--container button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.login-form--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
