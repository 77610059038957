.add-book-to-wishlist-modal--container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-book-to-wishlist-modal--container h3 {
  margin-bottom: 0;
}

.add-book-to-wishlist-modal--ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  /* overflow-y: scroll; */
  max-height: 80vh;
}

.add-book-to-wishlist-modal--item {
  list-style-type: none;
}

.add-book-to-wishlist-modal--item:hover {
  font-weight: bold;
  color: #113b58;
  cursor: pointer;
}

.add-book-to-wishlist-modal--item.clicked {
  font-weight: bold;
  color: #113b58;
}

.add-book-to-wishlist-modal--container button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.add-book-to-wishlist-modal--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
