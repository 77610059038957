.add-book-to-collection--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  /* overflow-y: scroll; */
  max-height: 80vh;
}

.add-book-to-collection--li {
  list-style-type: none;
}

.add-book-to-collection--li:hover {
  cursor: pointer;
  font-weight: bold;
}

.add-book-to-collection--li::selection {
  cursor: pointer;
  font-weight: bold;
}

.add-book-to-collection--container > button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.add-book-to-collection--container > button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
