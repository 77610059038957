.admin-portal-main--container {
  display: flex;
}

.admin-portal-main--collections {
  width: 100%;
}

.admin-portal-main--collections-header {
  display: flex;
  justify-content: center;
}

.admin-portal-main--collections-ul {
  display: flex;
  flex-wrap: wrap;
}

.admin-portal-main--collections-li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  max-width: 400px;
}

.admin-portal-main--collection-tile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  max-width: 300px;
  margin: 25px;
  padding: 10px;
}

.admin-portal-main--collection-tile:hover {
  background-color: #4e759149;
  transition: 0.25s ease-in-out;
}

.admin-portal-main--collection-tile:hover {
  cursor: pointer;
}

.admin-portal-main--collection-tile-book {
  padding: 5px;
}

.admin-portal-main--collection-tile-book img {
  width: 100%;
}

.admin-portal-main--collection-tile-empty {
  width: 300px;
  height: 471px;
  background-color: #4e7591;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-portal-main--collection-tile-empty p {
  padding: 10px;
  color: white;
}
