.wishlist-detail--container {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 40px;
}

.wishlist-detail--back-link {
  margin-top: 10px;
}

.wishlist-detail--back-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #113b58;
}

.wishlist-detail--header-and-edit {
  position: relative;
  width: fit-content;
}

.wishlist-title-edit {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #5c809e;
}

.wishlist-title-edit:hover {
  cursor: pointer;
}

.wishlist-title-edit.visible {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: -10px;
}

.wishlist-title-edit.hidden {
  display: none;
}

.wishlist-detail--delete-wishlist:hover {
  cursor: pointer;
}

.wishlist-detail--book-tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wishlist-detail--li {
  display: flex;
  justify-content: center;
  position: relative;
  list-style-type: none;
  width: fit-content;
  margin: 10px;
}

.wishlist-detail--img {
  max-width: 100%;
  height: auto;
  max-height: 500px;
}

.wishlist-detail--li-functions.visible {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
}

.wishlist-detail--li-functions {
  transition: all 1s ease-in-out;
}

.wishlist-detail--li-functions i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: 2px;
  background-color: #dbdada;
}

.wishlist-detail--li-functions i:hover {
  cursor: pointer;
}

.wishlist-detail--li-functions.hidden {
  display: none;
}
