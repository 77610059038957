.edit-collection-modal--container,
.edit-collection-modal--container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.edit-collection-modal--container input {
  width: 100%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 30px;
  margin: 5px;
}

.edit-collection-modal--container textarea {
  width: 100%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 60px;
  margin: 5px;
  padding: 5px;
}

.edit-collection-modal--container label {
  color: #4b4b4b;
  width: 500px;
}

.edit-collection-modal--container button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.edit-collection-modal--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
