.create-wishlist-modal--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  object-fit: contain;
}

.create-wishlist-modal--container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-wishlist-modal--container input {
  width: 95%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 30px;
  margin: 5px;
}

.create-wishlist--form-label {
  color: rgb(126, 126, 126);
  width: 500px;
}

.create-wishlist--form-label label {
  align-self: flex-start;
}

.create-wishlist-modal--container button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.create-wishlist-modal--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
