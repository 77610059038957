.landing--container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-featured-carousel--heading,
.landing-featured-carousel-item--component {
  display: flex;
  justify-content: center;
}

.landing-featured-carousel--heading:hover {
  cursor: pointer;
  color: #5c809e;
}

.landing-featured-carousel-item--component {
  margin-top: 0;
}
.landing-featured-carousel--heading {
  margin-bottom: 0;
}

.landing-featured--img {
  max-width: 325px;
  max-height: 445px;
}
.landing-featured--img:hover {
  cursor: pointer;
}

/* Featured Book Blurb */
.landing-book-blurb-hidden {
  display: none;
}

.landing-book-blurb--visible {
  display: flex;
  flex-wrap: wrap;
}
