.remove-book-from-wishlist-modal--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 5px; */
}

.remove-book-from-wishlist-modal--container button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.remove-book-from-wishlist-modal--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
