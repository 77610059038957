.nav-search-bar-dropdown--container {
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  position: absolute;
  padding: 3px;
  width: 400px;
}

.nav-search-bar-dropdown--container link:hover {
  font-weight: bold;
  cursor: pointer;
}

.nav-search-bar-dropdown--container link:visited {
  text-decoration: none;
  color: #000000;
}

.nav-search-bar-dropdown--searchtile > p {
  text-decoration: none;
  color: #000000;
}

.nav-search-bar-dropdown--searchtile > p:hover {
  font-weight: bold;
  color: #5c809e;
}
