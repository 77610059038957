.book-detail--container {
  display: grid;
  grid-template-columns: 40% auto auto auto;
  grid-template-areas:
    "image image title title title"
    "image image author author author"
    "image image stars reviews write-review"
    "image image price price price"
    "image image format format format"
    "image image    .    cart-bttn  wishlist-bttn"
    "image image desc desc desc";
  margin-top: 40px;
  margin-left: 60px;
  margin-right: 60px;
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
}

.book-detail--rating-filled {
  color: #000000;
}

.book-detail--rating-empty {
  color: #b4b3b3;
}

.book-detail--title {
  grid-area: title;
  display: flex;
}

.book-detail--img {
  grid-area: image;
  max-width: 400px;
  min-width: 350px;
  padding-right: 30px;
}

.book-detail--author {
  grid-area: author;
}

.book-detail--stars {
  grid-area: stars;
  display: flex;
}

.book-detail--numRatings {
  grid-area: reviews;
}

.book-detail-write-review-text {
  grid-area: write-review;
}

.book-detail-write-review-text:hover {
  cursor: pointer;
  font-weight: bold;
  color: #5c809e;
}

.book-detail-write-review-text-existing-review {
  color: #0000007c;
}

.book-detail-write-review-text-existing-review:hover {
  cursor: not-allowed;
}

.book-detail--price {
  grid-area: price;
}

.book-detail--format {
  grid-area: format;
}

.book-detail--cart-button {
  grid-area: cart-bttn;
  margin: 5px;
  background-color: #113b58;
  color: #ffffff;
  border-radius: 5px;
  padding: 2px;
  border: none;
}

.book-detail--wishlist-button {
  grid-area: wishlist-bttn;
  margin: 5px;
  background-color: #113b58;
  color: #ffffff;
  border-radius: 5px;
  padding: 2px;
  border: none;
}

.book-detail--cart-button:hover,
.book-detail--wishlist-button:hover {
  cursor: pointer;
  background-color: #5c809e;
}

.book-detail--cart-button:active,
.book-detail--wishlist-button:active {
  background-color: #5c809e;
}

.book-detail--description {
  grid-area: desc;
}

.admin-button {
  background-color: #f2f4cb;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}
