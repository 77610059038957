.create-review-modal--container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

#penName {
  width: 100%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 30px;
  margin: 5px;
}

.create-review-modal--container textarea {
  width: 100%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 60px;
  margin: 5px;
  padding: 5px;
}

.create-review-modal--submit-button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.create-review-modal--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}

.create-review-modal--no-user {
  display: flex;
  flex-direction: column;
}
.create-review-modal--no-user-button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 0;
}

.create-review-modal--no-user-button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}

/* CSS FOR STARS */
.create-review-modal--stars {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.create-review-modal--rating-filled {
  color: #113b58;
}

.create-review-modal--rating-empty {
  color: #113a5860;
}

.create-review-modal--rating-empty:hover,
.create-review-modal--rating-filled:hover {
  cursor: pointer;
}

.create-review-modal--rating-filled:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
