/* Carousel */
.carousel {
  overflow: hidden;
  max-width: 600px;
}

.carousel-inner {
  white-space: nowrap;
  transition: transform 0.3s;
  height: 600px;
  width: 600px;
}

.carousel--indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.carousel-indicator--arrow {
  margin: 5px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}

.carousel-indicator--arrow:hover {
  color: #5c809e;
  cursor: pointer;
}

.carousel-indicator--arrow:active {
  color: #47637a;
}

/* Carousel item */
.carousel-item {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 550px;
  width: 550px;
  /* background-color: #344e41; */
  background-color: #113b58;

  color: #fff;
}
