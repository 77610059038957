/* -------------------Profile dropdown------------------------ */
.profile-dropdown {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 10px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-dropdown > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-dropdown > div:hover {
  cursor: default;
}

.main-nav--admin-link {
  font-weight: bold;
  width: max-content;
}

.main-nav--admin-link:hover {
  cursor: pointer;
  color: #5c809e;
}

.main-navigation--profile-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-nav--profile-greeting {
  color: #5c809e;
  margin-left: 5px;
}
.main-nav--profile-greeting:hover {
  cursor: default;
}

.main-nav-profile--logout {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 75px;
}

.main-nav-profile--logout:hover {
  cursor: pointer;
  background-color: #6e98bb;
}

.login-button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  width: 75px;
}

.login-button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}

/* -------------------------Main nav----------------------------- */

.navigation--main {
  border-bottom: 1px solid rgb(150, 150, 150);
  max-width: 1920px;
}

.hidden {
  display: none;
}
.main-navigation--container {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  gap: 2rem;
  height: 100px;
  padding: 0 40px;
}

.main-navigation--loaded-div {
  display: flex;
  justify-content: baseline;
}

.main-navigation--logo {
  grid-area: logo;
}

.main-navigation--little-buttons {
  display: flex;
  gap: 0.5em;
}

.main-navigation--wishlist {
  color: #000000;
  position: relative;
}

.main-navigation--profile-button {
  grid-area: profile;
}

.main-navigation--wishlist > button {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #e6a2a2;
}

.main-navigation--wishlist > button:hover,
.main-navigation--cart:hover,
.main-navigation--profile-button:hover {
  cursor: pointer;
}

.main-navigation--cart {
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-navigation--container a,
a:visited {
  text-decoration: none;
}

.main-navigation--container li {
  list-style: none;
}

.main-navigation--clickable-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 1px;
}

.main-navigation--clickable-nav > span:hover {
  cursor: pointer;
  font-weight: bold;
  color: #5c809e;
}

.main-navigation--clickable-nav > span:active {
  color: #113b58bd;
}

.main-navigation--clickable-nav-category {
  position: relative;
}

/* -------------------Wishlist Dropdown---------------------------- */

.wishlist-dropdown--icon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #e6a2a2;
  position: relative;
}

.wishlist-dropdown--icon:hover {
  cursor: pointer;
}
.wishlist-dropdown {
  position: absolute;
  z-index: 1;
  /* left: 0;
  right: 0; */
  transform: translate(-50%, 0);
}

.wishlist-dropdown > div {
  /* position: absolute; */
  /* z-index: 1; */
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  min-width: 50px;
}

.wishlist-dropdown li:hover,
.wishlist-dropdown span:hover {
  cursor: pointer;
  font-weight: bold;
  color: #5c809e;
}
