.sign-up--modal-header {
  display: flex;
  justify-content: center;
}

.sign-up--modal {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  overflow-y: scroll;
  max-height: 80vh;
}

.sign-up--modal-accounttype {
  display: none;
}

.sign-up--modal input,
select {
  width: 100%;
  background-color: #e1e5eb;
  border-radius: 15px;
  border: none;
  min-height: 30px;
  margin: 5px;
}

.sign-up--modal > label {
  color: #4b4b4b;
  width: 500px;
}

.sign-up--modal > button {
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.sign-up--modal > button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}

.sign-up--modal--existing-user {
  display: flex;
  flex-direction: row;
}

.sign-up--modal--existing-user:hover {
  cursor: default;
}

.sign-up-modal--existing-user-login:hover {
  cursor: pointer;
  font-weight: bold;
}
