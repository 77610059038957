.loading-spinner {
  width: 48px;
  height: 40px;
  margin-top: 30px;
  display: inline-block;
  position: relative;
  background: #113b58;
  border-radius: 15% 15% 35% 35%;
}
.loading-spinner::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 45px;
  top: 8px;
  border: 4px solid #113b58;
  width: 16px;
  height: 20px;
  border-radius: 0 4px 4px 0;
}
.loading-spinner::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  color: #113b58;
  top: -15px;
  left: 11px;
  box-sizing: border-box;
  animation: animloader 1s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 2px 0px rgba(7, 7, 7, 0), 12px 0px rgba(7, 7, 7, 0.3),
      20px 0px rgba(7, 7, 7, 0);
  }
  50% {
    box-shadow: 2px -5px rgba(7, 7, 7, 0.5), 12px -3px rgba(7, 7, 7, 0.5),
      20px -2px rgba(7, 7, 7, 0.6);
  }
  100% {
    box-shadow: 2px -8px rgba(7, 7, 7, 0), 12px -5px rgba(7, 7, 7, 0),
      20px -5px rgba(7, 7, 7, 0);
  }
}

.loading-spinner--container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
