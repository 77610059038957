#nav-search-bar {
  min-width: 350px;
  position: relative;
}

.fa-magnifying-glass {
  padding-right: 5px;
  color: #113b58;
}
/* 
.nav-search-bar-dropdown {
  top: 100%;
  left: 0;
  right: 0;
} */
