.delete-book--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.delete-book--buttons > button {
  width: 150px;
  background-color: #5c809e;
  color: #ffffff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem;
}

.delete-book--buttons > button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}
