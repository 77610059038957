.book-detail-reviews--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-top: 1px solid #000000; */
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.book-detail-reviews--container h1 {
  margin-top: 0;
}

.book-detail-reviews--reviews {
  list-style-type: none;
}

.book-detail-reviews--empty-reviews {
  text-decoration: underline;
}

.book-detail-reviews--empty-reviews:hover {
  cursor: pointer;
  color: #5c809e;
  font-weight: bold;
}

.book-detail-reviews--review-li {
  border-bottom: 1px solid #000000;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  grid-template-areas:
    "rating     review"
    "name       review"
    "spoiler    review";
  margin: 5px;
  width: 90vw;
}

.book-detail-reviews--review-spoiler {
  filter: blur(4px);
}

.book-detail-reviews--review-spoiler.revealed {
  filter: none;
}

.book-detail-reviews--review-spoiler:hover {
  cursor: pointer;
}

.book-detail-reviews--rating {
  grid-area: rating;
}

.book-detail-reviews--penName {
  grid-area: name;
}

.book-detail-reviews--spoiler {
  grid-area: spoiler;
}

.yes-spoiler-check {
  color: #008000;
}

.no-spoiler-x {
  color: #ff0000;
}

.book-detail-reviews--review {
  grid-area: review;
  padding: 5px;
}

.review-user-icons:hover {
  cursor: pointer;
  color: #6e98bb;
}
