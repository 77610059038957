.browse-collections--container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.browse-collections--container p {
  margin: 0;
}

.browse-collections--ul {
  display: flex;
  flex-wrap: wrap;
}

.browse-collections--li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  max-width: 400px;
}

.browse-collections--li h3 {
  margin-bottom: 0;
}

.browse-collections--tile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  max-width: 300px;
  height: 100%;
  margin: 20px;
  padding: 10px;
  background-color: #113b58;
}

.browse-collections--tile:hover {
  cursor: pointer;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
}

.browse-collections--tile-book img {
  width: 100%;
}
.browse-collections--tile-book {
  padding: 5px;
}
