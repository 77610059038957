.login-modal--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 70px;
  padding: 10px;
}

.login-modal--container h1 {
  margin: 0;
  padding: 0;
}

.login-modal--container form {
  display: flex;
  flex-direction: column;
}

.login-modal--container input {
  margin: 3px;
  padding: 5px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.login-modal--container button {
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  margin: 3px;
  padding: 5px;
  background-color: #5c809e;
  color: #ffffff;
}

.login-modal--container button:hover {
  cursor: pointer;
  background-color: #6e98bb;
}

.login-modal--demo {
  display: flex;
  gap: 5px;
}

.login-modal--demo > span:hover {
  cursor: pointer;
  font-weight: bold;
}
