.collection-detail--container {
  display: flex;
  flex-direction: column;
}

.collection-detail--header {
  display: flex;
  margin: 0;
}

.collection-detail--ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.collection-detail--li {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  width: auto;
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.collection-detail--li:hover {
  cursor: pointer;
  background-color: #4e759149;
  transition: 0.25s ease-in-out;
}

.collection-detail--li img {
  max-height: 400px;
}

.collection-detail--x:hover {
  font-weight: bold;
}

.collection-detail--stars {
  display: flex;
}

.collection-detail--rating-filled {
  color: #000000;
}

.collection-detail--rating-empty {
  color: #b4b3b3;
}
